<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Column Area  -->
    <div class="rn-column-area rn-section-gap bg_color--5 mt--150">
      <v-container>
          <v-row>
              <v-col lg="4"
                     md="4"
                     sm="6"
                     cols="12">
                  <div class="single-column">
                      
                  </div>
              </v-col>
              <v-col lg="6"
                     md="6"
                     sm="6"
                     cols="12">
                  <div class="single-column">
                     <img src="@/assets/images/blog/promocode.png" />
                     <h5>Εισάγετε το PromoCode & κερδίστε το δώρο σας</h5>
                      <div class="form-wrapper">
                        <form @submit.prevent>
                            <label>
                                <input type="text" name="username" placeholder="Όνομα Χρήστη/Κινητό Τηλέφωνο *" />
                            </label>
                            <button class="rn-button-style--2 btn_solid m--4"
                            style="margin-top: 15%;"
                                    type="submit"
                                    value="submit" @click="signIn">
                                Σύδεση
                            </button>
                        </form>
                    </div>
                  </div>
              </v-col>

              <v-col lg="2"
                     md="2"
                     sm="6"
                     cols="12">
                  <div class="single-column">
                  </div>
              </v-col>
          </v-row>
      </v-container>
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  
  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "up  για τεχνολογία",
            to: "",
            disabled: true,
          },
        ],
        
      };
    },
  };
</script>
